@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MaaxRounded';
  src: url('../fonts/MaaxRounded.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MaaxRounded';
  src: url('../fonts/MaaxRounded-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MaaxRounded';
  src: url('../fonts/MaaxRounded-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
