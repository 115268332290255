* {
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
body {
  color: #4a4a4a;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
  
}
.slick-slide div {
  outline: 0;
}
.dandan-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 16px solid #fff;
  border-radius: 50%;
  border-top: 16px solid #f27b3a;
  width: 300px;
  height: 300px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.btn-google {
  width: 100%;
  box-shadow: none !important;
  margin-top: 15px;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  justify-content: center;
}
.btn-facebook {
  width: 100%;
  box-shadow: none !important;
  margin-top: 15px;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  text-align: center;
  justify-content: center;
  padding: 10px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  font-size: 14px;
}
.slick-prev:before {
  color: #4a4a4a;
}
.slick-next:before {
  color: #4a4a4a;
}
.navbar-toggle {
  display: block !important;
  margin: 4px 0 0;
  position: relative;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.icon-bar {
  background-color: #fff;
  height: 3px;
  width: 24px;
  transition: all 0.2s;
  display: block;
  margin: 4px 0;
  border-radius: 4px;
}
.top-bar {
  transform: translate(6px) rotate(45deg);
  moz-transform-origin: 15% 15%;
  -ms-transform-origin: 15% 15%;
  -webkit-transform-origin: 15% 15%;
  transform-origin: 15% 15%;
}
.middle-bar {
  opacity: 0;
}
.bottom-bar {
  transform: translate(9px) rotate(-45deg);
  moz-transform-origin: 10% 140%;
  -ms-transform-origin: 10% 140%;
  -webkit-transform-origin: 10% 140%;
  transform-origin: 0% 160%;
}
.navbar-toggle.collapsed .top-bar {
  transform: rotate(0);
}
.navbar-toggle.collapsed .middle-bar {
  opacity: 1;
}
.navbar-toggle.collapsed .bottom-bar {
  transform: rotate(0);
}
.react-daterange-picker__wrapper {
  border: 1px solid #999 !important;
  border-radius: 10px;
  padding: 5px 10px;
  color: #4a4a4a !important;
}
.react-daterange-picker__wrapper input {
  border: 0 !important;
  color: #4a4a4a !important;
  outline: 0 !important;
  /* padding: 0 2px; */
}
.react-daterange-picker__calendar {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}
.react-daterange-picker__calendar .react-calendar {
  border-radius: 5px;
}
.react-daterange-picker__range-divider {
  margin: 2px 5px 0;
  color: #009ec2;
}
.react-calendar__tile--active {
  background: #009ec2 !important;
}
.slick-slide {
  /* line-height: 0; */
}
.dandan-loader {
  -webkit-animation: fade 1s linear infinite; /* Safari */
  animation: fade 1s linear infinite;
}
.slick-dots {
  bottom: 0 !important;
}
.link:hover {
  text-decoration: none;
}
.rounded {
  border-radius: 10px !important;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  background-color: white;
}
.content-slider.slick-slider div {
  line-height: 0;
}
.paging-arrow:before {
  font-size: 18px !important;
  line-height: 1.3 !important;
}
.banner .slick-next:before,
.banner .slick-prev:before,
.content-slider .slick-next:before,
.content-slider .slick-prev:before,
.product-group .slick-next:before,
.product-group .slick-prev:before {
  content: '';
}
/* .best-scrollview div:first-child {
  overflow-y: auto !important;
  overflow-x: hidden !important;
} */
.best-scrollview > div:nth-child(1) {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.best-scrollview > div:nth-child(2) {
  display: none;
}

.image-style-align-right {
  float: right;
  padding: 15px;
}
.image-style-align-left {
  float: left;
  padding: 15px;
}
.about p {
  word-break: break-all;
}
.image_resized {
  display: block;
  box-sizing: border-box;
}
.image_resized:not(.image-style-align-right) {
  margin: 0 auto;
}
.image_resized:not(.image-style-align-left) {
  margin: 0 auto;
}
.image_resized img {
  width: 100%;
}
figure img {
  width: 100%;
}
.search-brand::placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
}
.search-brand::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
}
.search-brand::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}
.search-brand:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px;
}
.search-brand:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}
@media (max-width: 768px) {
  .product-group .slick-prev {
    left: -10px !important;
  }
  .product-group .slick-next {
    right: -10px !important;
  }
  .slick-dots {
    bottom: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .content-slider .content-slider-item {
    height: 150px !important;
  }
  .other-blog {
    margin-left: 0;
    width: 33.33%;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input,
  select,
  textarea,
  .react-daterange-picker__inputGroup__leadingZero {
    font-size: 16px !important;
  }
}

.promo-text{
  animation: blink 2s linear infinite;
}

@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: 1;}
  100%{opacity: 2;}
}

li .menu-title:hover{
  color: #f27b3a;
}

.submenu-list{
  display: none;
  width: 100%;
}

a.link-menu-hover:hover{
  color: #009ec2;
}

ul.product-category-list{
  list-style: none;
  padding: 0;
  margin: 0;
}
.product-category-list li{
  margin-bottom: 6px;
}

.product-category-list li a{
  
  color: #4a4a4a;
  font-weight: medium;
  font-size: 12px;
}

/* Set icon on right */
.product-category-list li a>span{
  float: right;
  color: #4a4a4a;
  font-weight: normal;
  font-size: 11px;
  margin-top: 5px;
}

.product-category-list li a:hover{
  color: #f27b3a;
}

.product-category-list li ul.sub-category-list{
  margin-left: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

ul.sub-category-list li a{
  margin-bottom: 10px;
  margin-left: 10px;
  color: #4a4a4a;
  font-weight: normal;
  font-size: 11px;
}

ul.sub-category-list li a.active{
  color: #f27b3a;
}

ul.sub-category-list li a:hover{
  color: #f27b3a;
}

.suboverlaymenu{
  position: absolute;
  top: 0;
  left: 180px;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* max-width: 930px; */
  width: calc(100% - 200px);
  overflow: hidden;
  -webkit-box-shadow: 3px 5px 6px -1px rgba(0,0,0,.16);
  box-shadow: 3px 5px 6px -1px rgba(0,0,0,.16);
  background: #fff;
  border: 1px solid #f3f3f3;
  border-left: 0;
  border-radius: 0 0 4px 0;
}

@media screen and (max-width: 1024px) {
  .megamenu-wrapper{
    width: 55%;
  }
}

@media screen and (min-width: 1025px) {
    .megamenu-wrapper{
      width: 40%;
    }
}

@media only screen and (min-width: 960px) {
  .megamenu-wrapper{
    width: 55%;
  }
}
@media only screen and (min-width: 1290px) {
  .megamenu-wrapper{
    width: 50%;
  }
}
@media only screen and (min-width: 1900px) {
  .megamenu-wrapper{
    width: 44%;
  }
}

